<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Resolver reclamo</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="buttons is-centered">
              <button type="button" class="button" :class="{ 'button-givu': typeResolve === BUYER }" @click="setTypeResolve(BUYER)">
                Vendedor
              </button>
              <button type="button" class="button" :class="{ 'button-givu': typeResolve === SELLER }" @click="setTypeResolve(SELLER)">
                Comprador
              </button>
              <button type="button" class="button" :class="{ 'button-givu': typeResolve === REJECTED }" @click="setTypeResolve(REJECTED)">
                Preaprobar
              </button>
          </div>
          <div class="field">
            <label class="label">Comentario</label>
            <div class="control">
              <textarea
                class="textarea"
                type="text"
                v-model="message"
                name="message"
                :class="{ 'is-danger': vverrors.first('message') }"
                v-validate="'required'"
              ></textarea>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" :class="{ 'is-loading': loading }" :disabled="loading" @click="saveResponseLocal()">Resolver</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    saveCloseResponse: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      message: null,
      typeResolve: null,
      loading: false,
      BUYER: 1,
      SELLER: 2,
      REJECTED: 3
    }
  },
  methods: {
    setTypeResolve (type) {
      this.typeResolve = type
    },
    async saveResponseLocal () {
      let data = {
        favor: this.typeResolve,
        message: this.message
      }
      if (await this.$validator.validateAll()) {
        this.loading = true
        this.saveCloseResponse(data)
      }
    }
  }
}
</script>
